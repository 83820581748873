import { memo } from 'react';
import { AnchorButtonMemo } from '../../../buttons/button/button';
import { ARROW_NAV_TARGET_CLASS } from '../../../../utils/nav-system';
import styles from './games-banner.module.css';

export function GamesBanner() {
  return (
    <div className={styles.root}>
      <div className={styles.bg}>
        <img
          src="/games-banner-bg.jpg"
          alt="Games Banner Background"
          loading="lazy"
          decoding="async"
        />
        <div className={styles.bgEdgeFade}></div>
      </div>
      <div className={styles.bgLeft}>
        <img
          src="/games-banner-left.avif"
          alt="Games Banner Left Image"
          loading="lazy"
          decoding="async"
        />
      </div>
      <div className={styles.bgRight}>
        <img
          src="/games-banner-right.avif"
          alt="Games Banner Right Image"
          loading="lazy"
          decoding="async"
        />
      </div>
      <h2>
        Can&rsquo;t decide?
        <br />
        Check out all our games
      </h2>
      <AnchorButtonMemo theme="Primary" className={`${ARROW_NAV_TARGET_CLASS}`} href="/games">
        Explore All Games
      </AnchorButtonMemo>
      <div className={styles.topHighlight}></div>
    </div>
  );
}

export const GamesBannerMemo = memo(GamesBanner);
