import { IMAGE_OPTIMIZATION_DEVICE_SIZES, IMAGE_OPTIMIZATION_QUALITY } from '../constants.mjs';

// Maintain a cache of the preload requests so we don't make duplicate requests.
// This cache needs to be purged when the window is resized.
const requestCache: Map<string, undefined> = new Map();

// On the browser-side, we want to purge the cache when the window is resized.
// The reason for this is that the window size can affect the image sizes that
// are preloaded.
if (typeof window !== 'undefined') {
  window.addEventListener('resize', () => {
    requestCache.clear();
  });
}

export function preloadImage(src: string, sizes: string): Promise<undefined> {
  return new Promise((resolve, reject) => {
    // Create a cache key for the request.
    const cacheKey = `${sizes}-${src}`;

    // Check if the image has already been preloaded with the same sizes value.
    if (requestCache.has(cacheKey)) {
      return resolve(undefined);
    }

    // Add the request to the cache.
    requestCache.set(cacheKey, undefined);

    // Construct the base URL for the image optimization.
    const baseUrl = '/_next/image?url=' + encodeURIComponent(src);

    // Construct the srcset attribute by iterating over defined widths
    const srcset = IMAGE_OPTIMIZATION_DEVICE_SIZES.map(
      (w) => `${baseUrl}&w=${w}&q=${IMAGE_OPTIMIZATION_QUALITY} ${w}w`,
    ).join(', ');

    // Get the maximum device size.
    const maxSize = Math.max(...IMAGE_OPTIMIZATION_DEVICE_SIZES);

    // Create an off-DOM image to preload.
    const img = new Image();
    img.onload = () => {
      resolve(undefined);
    };
    img.onerror = (error) => {
      reject(error);
    };
    img.decoding = 'async';
    img.loading = 'eager';
    img.alt = '';
    img.sizes = sizes;
    img.srcset = srcset;

    // Set the src as last since the image starts loading when the src is set.
    // Also note that we are setting the src to the largest size since that's
    // what NextJS Image component does too, and we are trying to match it's
    // behavior.
    img.src = `${baseUrl}&w=${maxSize}&q=${IMAGE_OPTIMIZATION_QUALITY}`;
  });
}
