import { useCallback, useEffect, useRef, useState, MouseEventHandler, memo } from 'react';
import { BaseButtonMemo } from '../buttons/base-button/base-button';
import { AnchorButtonMemo } from '../buttons/button/button';
import { CloseIcon } from '../svg-icons/close-icon';
import { useStore } from '../../providers/store-provider';
import {
  STORAGE_KEY_PLAYER_QUESTIONNAIRE_DISMISSED,
  STORAGE_KEY_PLAYER_QUESTIONNAIRE_DISMISSED_DATE,
  STORAGE_KEY_PLAYER_QUESTIONNAIRE_DISMISSED_COUNT,
  SIDE_PANEL_ANCHOR_CLASS,
} from '../../constants.mjs';
import styles from './player-questionnaire.module.css';

const ONE_DAY = 1000 * 60 * 60 * 24;
const THREE_DAYS = 1000 * 60 * 60 * 24 * 3;
const SEVEN_DAYS = 1000 * 60 * 60 * 24 * 7;

/*
 * First-Time Display:
 * Show the CTA 24 HOURS after the user has completed their first game and closed the
 * game tab, returning to the portal.
 *
 * No Display After Engagement:
 * If the user clicks on "Talk to Us," the CTA should not be displayed again
 * for 7 days.
 *
 * Re-Display After First Dismissal:
 * If the user dismisses the CTA the first time, it should reappear 24 hours
 * later.
 *
 * Re-Display After Multiple Dismissals:
 * If the user dismisses the CTA for a second time (or any subsequent times),
 * it should reappear 3 days later.
 */

export function PlayerQuestionnaire() {
  const [isStickerDismissed, setStickerDismissed] = useState(true);
  const [isVisible, setVisible] = useState(false);
  const recentlyPlayedGames = useStore((state) => state.recentlyPlayedIds?.length || 0);
  const timerRef = useRef<number | null>(null);

  const handleDismiss = useCallback((cutoff: number) => {
    const count =
      Number(window.localStorage.getItem(STORAGE_KEY_PLAYER_QUESTIONNAIRE_DISMISSED_COUNT)) || 0;

    window.localStorage.setItem(STORAGE_KEY_PLAYER_QUESTIONNAIRE_DISMISSED, 'true');
    window.localStorage.setItem(STORAGE_KEY_PLAYER_QUESTIONNAIRE_DISMISSED_DATE, `${cutoff}`);
    window.localStorage.setItem(STORAGE_KEY_PLAYER_QUESTIONNAIRE_DISMISSED_COUNT, `${count + 1}`);

    setVisible(false);
    timerRef.current = window.setTimeout(() => {
      setStickerDismissed(true);
    }, 500);
  }, []);

  const handleClose = useCallback(() => {
    if (!isVisible) return;

    const count =
      Number(window.localStorage.getItem(STORAGE_KEY_PLAYER_QUESTIONNAIRE_DISMISSED_COUNT)) || -1;

    // the first time it's dismissed without cutoff, it will show up again after
    // a day, otherwise it will show up after 3 days.
    const cutoff = count === 0 ? ONE_DAY : THREE_DAYS;

    handleDismiss(Date.now() + cutoff);
  }, [isVisible, handleDismiss]);

  const handleOpenForm: MouseEventHandler = useCallback(
    () => handleDismiss(Date.now() + SEVEN_DAYS),
    [handleDismiss],
  );

  const onVisibilityChanged = useCallback(() => {
    /*
     * On visibility changed, we check if the sticker has been previously dismissed
     * and if the cutoff date has been reached. If so, we show the sticker again.
     *
     * if there is no cutoff date, it means it has been  dismissed before we
     * introduced the new rules so setting the cutoff date to 0 will just show
     * it again and dismissing will apply new rules.
     */
    const count = Number(
      Number(window.localStorage.getItem(STORAGE_KEY_PLAYER_QUESTIONNAIRE_DISMISSED_COUNT) || -1),
    );
    const dismissedDate = Number(
      window.localStorage.getItem(STORAGE_KEY_PLAYER_QUESTIONNAIRE_DISMISSED_DATE) || 0,
    );
    const hasBeenDismissed =
      window.localStorage.getItem(STORAGE_KEY_PLAYER_QUESTIONNAIRE_DISMISSED) === 'true';

    const cutoffTimestamp = Number(new Date(dismissedDate));
    const cutoffReached = Date.now() - cutoffTimestamp > 0;

    // This is the first FIRST time we want to display the form but we want to wait ONE DAY
    // before showing it
    if (recentlyPlayedGames > 0 && count === -1) {
      window.localStorage.setItem(STORAGE_KEY_PLAYER_QUESTIONNAIRE_DISMISSED, 'true');
      window.localStorage.setItem(STORAGE_KEY_PLAYER_QUESTIONNAIRE_DISMISSED_COUNT, '0');
      window.localStorage.setItem(
        STORAGE_KEY_PLAYER_QUESTIONNAIRE_DISMISSED_DATE,
        `${Date.now() + ONE_DAY}`,
      );
      return;
    } else if (count === -1) {
      // intercept here, for some reason no games have been played yet, don't do anything
      return;
    }

    // if cutoff date has been reached, show the sticker again
    if ((hasBeenDismissed && cutoffReached) || !hasBeenDismissed) {
      window.localStorage.setItem(STORAGE_KEY_PLAYER_QUESTIONNAIRE_DISMISSED, 'false');
      setStickerDismissed(false);
      setVisible(true);
    }
  }, [recentlyPlayedGames]);

  useEffect(() => {
    onVisibilityChanged();
    window.addEventListener('visibilitychange', onVisibilityChanged);
    return () => {
      window.removeEventListener('visibilitychange', onVisibilityChanged);
      if (timerRef.current) window.clearTimeout(timerRef.current);
    };
  }, [onVisibilityChanged]);

  if (isStickerDismissed) return null;

  // Construct the root class name based on the visibility state.
  let rootClassName = styles.root;
  if (isVisible) {
    rootClassName += ` ${styles.isVisible}`;
  } else {
    rootClassName += ` ${styles.isHidden}`;
  }

  return (
    <div className={rootClassName}>
      <div className={styles.content}>
        <div className={styles.leftColumn}>
          <p className={styles.description}>Give feedback or suggest a feature.</p>
          <AnchorButtonMemo
            href="https://5afdcbu1ar0.typeform.com/raybrowser"
            className={`${styles.formButton} ${SIDE_PANEL_ANCHOR_CLASS}`}
            onClick={handleOpenForm}
          >
            Talk to us
          </AnchorButtonMemo>
        </div>
        <BaseButtonMemo className={styles.closeButton} onClick={handleClose}>
          <CloseIcon className={styles.closeIcon} />
        </BaseButtonMemo>
      </div>
    </div>
  );
}

export const PlayerQuestionnaireMemo = memo(PlayerQuestionnaire);
