import { useCallback, useMemo, memo } from 'react';
import { useRouter } from 'next/router';
import { useApolloClient } from '@apollo/client';
import { GetItemDetail } from '../../graphql-queries';
import { AnchorButtonMemo, AnchorButtonProps } from '../buttons/button/button';
import { sanitizeRouterPathname } from '../../utils/sanitize-router-pathname';
import { preloadImage } from '../../utils/preload-image';
import { IMAGE_SIZES } from '../../constants.mjs';
export type DetailLinkProps = Omit<AnchorButtonProps, 'href'> & {
  itemId: string;
};

export function DetailLink({
  itemId,
  children,
  onFocus,
  onPointerEnter,
  ...restProps
}: DetailLinkProps) {
  const router = useRouter();
  const apolloClient = useApolloClient();

  const href = useMemo(() => {
    const pathname = sanitizeRouterPathname(router.pathname);
    const searchParams = new URLSearchParams({ ...router.query, info: itemId });
    const searchString = searchParams.toString();
    return `${pathname}?${searchString}`;
  }, [itemId, router.pathname, router.query]);

  const prefetch = useCallback(() => {
    apolloClient
      .query({
        query: GetItemDetail,
        variables: { itemId },
      })
      .then(({ data }) => {
        // Preload the image for the detail modal.
        const imageUrl = data.items?.[0]?.landscapeThumbnail?.url || '';
        if (imageUrl) {
          preloadImage(imageUrl, IMAGE_SIZES.DetailHeader);
        }
      })
      .catch((e) => {
        console.error(`Failed to prefetch game detail data: ${itemId}`);
        console.error(e);
      });
  }, [itemId, apolloClient]);

  return (
    <AnchorButtonMemo href={href} shallow prefetch={prefetch} {...restProps}>
      {children}
    </AnchorButtonMemo>
  );
}

export const DetailLinkMemo = memo(DetailLink);
