import { useEffect, useState } from 'react';
import { IS_RAY } from '../constants.mjs';

export function useIsRay() {
  // Let's always assume we are in Ray Browser so the app is always rendered
  // initially. The IS_RAY flag can only be computed on the client side so a
  // hydration error will occur if we don't unrender after useEffect.
  const [isRay, setRay] = useState(true);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    setRay(IS_RAY);
  }, []);

  return isRay;
}
