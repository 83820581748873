// @ts-check

export const IS_RAY = (() => {
  try {
    // @ts-ignore
    const isRealRay = window.navigator.userAgentData.brands.some(({ brand }) => brand === 'Ray');
    const isFakeRay = new URL(window.location.href).searchParams.get('isRay') !== null;
    return isRealRay || isFakeRay;
  } catch (e) {
    return false;
  }
})();

export const STORAGE_KEY_RECENTLY_PLAYED = 'recentlyPlayed';

export const STORAGE_KEY_SCROLL_RESTORATION = 'scrollRestoration';

export const STORAGE_KEY_PLAYER_QUESTIONNAIRE_DISMISSED = 'player-questionnaire-dismissed';

export const STORAGE_KEY_PLAYER_QUESTIONNAIRE_DISMISSED_DATE =
  'player-questionnaire-dismissed-date';

export const STORAGE_KEY_PLAYER_QUESTIONNAIRE_DISMISSED_COUNT =
  'player-questionnaire-dismissed-count';

export const MAX_RECENTLY_PLAYED_ITEMS = 10;

export const SIDE_PANEL_ANCHOR_CLASS = 'side-panel-anchor';

export const STICKY_SEARCH_PARAMS = ['isRay', 'disableVideos'];

export const SCROLL_RESTORATION_TARGET_CLASS = 'scrollRestorationTarget';

export const SCROLL_RESTORATION_SELECTORS = ['#__next', `.${SCROLL_RESTORATION_TARGET_CLASS}`];

export const IMAGE_OPTIMIZATION_DEVICE_SIZES = [320, 480, 640, 768, 1024, 1440, 1920, 2560];

export const IMAGE_OPTIMIZATION_QUALITY = 85;

export const IMAGE_SIZES = {
  CardMedium:
    '(max-width: 619px) 80vw,(max-width: 863px) 42vw,(max-width: 1107px) 29vw,(max-width: 1351px) 22vw,(max-width: 1595px) 18vw,(max-width: 1839px) 15vw,(max-width: 2083px) 13vw, 12vw',
  CardLarge:
    '(max-width: 619px) 80vw,(max-width: 863px) 42vw,(max-width: 1351px) 30vw,(max-width: 1595px) 23vw,(max-width: 1839px) 18vw,(max-width: 2083px) 15vw, 13.5vw',
  DetailHeader: '(max-width: 1090px) 100vw, 950px',
  CategoryHeader: '100vw',
  CollectionHeader: '40vw',
  CollectionCard: '250px',
  SliderHeader: '100vw',
  SliderLogo: '250px',
  FeaturedCategoryLandscape: '(max-width: 1024px) 55vw, 40vw',
  FeaturedCategoryPortrait: '(max-width: 1024px) 35vw, 27vw',
};
