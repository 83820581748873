import type { GetIndexPageDataQuery } from '../../../.gql/graphql';
import { Fragment } from 'react';
import Head from 'next/head';
import { SliderMemo } from './slider/slider';
import { GamesBannerMemo } from './games-banner/games-banner';
import { MainContentMemo } from '../../main-content/main-content';
import { TrendingCardListMemo } from './trending-card-list/trending-card-list';
import { FeaturedCategoriesMemo } from './featured-categories/featured-categories';
import { CollectionMemo } from '../../collection/collection';
import { PopupCardListMemo } from '../../cards/popup-card-list/popup-card-list';
import { SpacerMemo } from '../../spacer/spacer';
import { useSlides } from './hooks/use-slides';
import { useTrendingItems } from './hooks/use-trending-items';
import { useFeaturedItems } from './hooks/use-featured-items';
import { useFeaturedCategories } from './hooks/use-featured-categories';
import { useCollections } from './hooks/use-collections';

export type IndexPageProps = {
  data: GetIndexPageDataQuery;
};

export function IndexPage({ data }: IndexPageProps) {
  const slides = useSlides(data);
  const trendingItems = useTrendingItems(data);
  const featuredCategories = useFeaturedCategories(data);
  const featuredItems = useFeaturedItems(data);
  const collections = useCollections(data);
  return (
    <>
      <Head>
        <title key="title">Ray Portal | Discover the best web games</title>
        <meta key="description" name="description" content="Discover the best web games." />
      </Head>
      <SliderMemo slides={slides} />
      <MainContentMemo>
        <SpacerMemo size="xl" />
        <TrendingCardListMemo id="trending" items={trendingItems} />
        <SpacerMemo size="xl" multiplier={3} />
        <FeaturedCategoriesMemo categories={featuredCategories} />
        {collections.map((collection, index) => (
          <Fragment key={collection.id}>
            <SpacerMemo size="xl" multiplier={index === 0 ? 3 : 2} />
            <CollectionMemo
              name={collection.name}
              items={collection.items}
              flipColumns={!!(index % 2)}
            />
          </Fragment>
        ))}
        <SpacerMemo size="xl" multiplier={3} />
        <PopupCardListMemo
          id="portals"
          headerTitle="Other Game Portals"
          cardSize="large"
          items={featuredItems}
        />
        <SpacerMemo size="xl" multiplier={2} />
        <GamesBannerMemo />
        <SpacerMemo size="xl" multiplier={3} />
      </MainContentMemo>
    </>
  );
}
