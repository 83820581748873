import { STORAGE_KEY_SCROLL_RESTORATION, SCROLL_RESTORATION_SELECTORS } from '../constants.mjs';

export interface ScrollRestorationPosition {
  scrollTop: number;
  scrollLeft: number;
}

export interface ScrollRestorationStorage {
  [pathname: string]: {
    [elementId: string]: ScrollRestorationPosition;
  };
}

export function getScrollableElements(): HTMLElement[] {
  const elements = new Set<HTMLElement>();
  SCROLL_RESTORATION_SELECTORS.forEach((selector) => {
    const selectorElements = document.querySelectorAll<HTMLElement>(selector);
    selectorElements.forEach((element) => {
      if (element.id && element instanceof HTMLElement) {
        elements.add(element);
      }
    });
  });
  return Array.from(elements);
}

function getScrollPositions(): Record<string, ScrollRestorationPosition> {
  const positions: Record<string, ScrollRestorationPosition> = {};
  const scrollableElements = getScrollableElements();
  scrollableElements.forEach((element) => {
    positions[element.id] = {
      scrollTop: element.scrollTop,
      scrollLeft: element.scrollLeft,
    };
  });
  return positions;
}

export function saveScrollPositions(pathname: string) {
  const positions = getScrollPositions();

  const currentData = JSON.parse(
    sessionStorage.getItem(STORAGE_KEY_SCROLL_RESTORATION) || '{}',
  ) as ScrollRestorationStorage;

  const nextData: ScrollRestorationStorage = {
    ...currentData,
    [pathname]: positions,
  };

  sessionStorage.setItem(STORAGE_KEY_SCROLL_RESTORATION, JSON.stringify(nextData));
}

export function restoreScrollPositions(pathname: string) {
  const scrollPositions = JSON.parse(
    sessionStorage.getItem(STORAGE_KEY_SCROLL_RESTORATION) || '{}',
  ) as ScrollRestorationStorage;

  // If we have stored scroll positions for the current path, restore them.
  if (scrollPositions[pathname]) {
    const positions = scrollPositions[pathname];
    // Loop the stored positions and restore them.
    Object.entries(positions).forEach(([elementId, position]) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollTo({
          top: position.scrollTop,
          left: position.scrollLeft,
          behavior: 'instant',
        });
      }
    });
  }
  // If we don't have stored scroll positions, scroll all scrollable elements to
  // to top/left.
  else {
    const scrollableElements = getScrollableElements();
    scrollableElements.forEach((element) => {
      element.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    });
  }
}

export function restoreTargetScrollPosition(pathname: string, targetId: string) {
  const scrollPositions = JSON.parse(
    sessionStorage.getItem(STORAGE_KEY_SCROLL_RESTORATION) || '{}',
  ) as ScrollRestorationStorage;

  if (!scrollPositions[pathname]) return;

  const positions = scrollPositions[pathname];
  const targetPosition = positions[targetId];

  if (!targetPosition) return;

  const element = document.getElementById(targetId);
  if (!element) return;

  element.scrollTo({
    top: targetPosition.scrollTop,
    left: targetPosition.scrollLeft,
    behavior: 'instant',
  });
}

export function clearScrollPositions(pathname: string) {
  const scrollPositions = JSON.parse(
    sessionStorage.getItem(STORAGE_KEY_SCROLL_RESTORATION) || '{}',
  ) as ScrollRestorationStorage;
  delete scrollPositions[pathname];
  sessionStorage.setItem(STORAGE_KEY_SCROLL_RESTORATION, JSON.stringify(scrollPositions));
}
